import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/LoginStyle'
import LoginForm from './Subcomponents/LoginForm'
export default class UserOrderComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.componentMethods.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            },
            showRecoverPasswordScreen: () => {
                that.props.componentMethods.setManagerState({recoverPasswordScreen: true})
            },
            hideRecoverPasswordScreen: () => {
                that.props.componentMethods.setManagerState({recoverPasswordScreen: false})
            },
            addError: (error, invalidFields = {}) => {
                that.addError(error, invalidFields)
            },
        })
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <LoginForm {...this.buildComponentProps('Appbar')} />
                </div>
    }
}
