import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Subscribers from './Subcomponents/Subscribers'

export default class SubscribersPage extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        this.getSubscribersFeedAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.getSubscribersFeedAction()
            },
            getLinksAction: (q) => {
                that.getLinksAction(q)
            },
            postSubscriberKeyword: (subscribersKeyword) => {
                that.postSubscriberKeyword(subscribersKeyword)
            }
        })
    }
    getSubscribersFeedAction() {
        const that = this;

        that.props.setManagerState({loadingNewsFeed: true, subscribersFeedItems: []},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS_FEED_NEW +  this.props.urlParams.subscribersID + '?limit=24&accountToken=' + that.getLocalStorage('user').tokensHash,{},'GET','subscribersFeed', (data) => {
                    that.props.setManagerState({oldestLinksID:0 ,subscribersFeedItems: data.items, loadingNewsFeed: false})

                })
            }    
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        { this.getLocalStorage("user") ?
                        <Subscribers {...this.buildComponentProps('Appbar')} />
                        :
                        null
                        }
                    </div>
                </div>
    }
}
