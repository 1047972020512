import React from 'react';
import { useParams
} from "react-router-dom";
import { Routes ,Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import FiltersList from '../Components/FiltersList/FiltersList'
import NewsItem from '../Components/NewsItemPage/NewsItemPage'
import Bookmarks from '../Components/Bookmarks/BookmarksPage'
import Trending from '../Components/Trending/TrendingPage'
import TrendingWhitelisted from '../Components/TrendingWhitelist/TrendingWhitelisted'
import Subscribers from '../Components/SubscribersPage/SubscribersPage';
import CreateAccount from '../Components/CreateAccount/CreateAccount';
import YourAccount from '../Components/YourAccount/YourAccount'
import Zopinions from '../Components/Zopinions/Zopinions';
import RecoverPasswordComponent from '../Components/RecoverPasswordComponent/RecoverPasswordComponent';

function NewsItemPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <NewsItem {...props} urlParams={urlParams} />
            </React.Fragment> 
}

function HomePage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Home {...props} urlParams={urlParams} />
            </React.Fragment>  
}
function BookmarksPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Bookmarks {...props} urlParams={urlParams} />
            </React.Fragment>  
}
function TrendingWhitelistedPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <TrendingWhitelisted {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function TrendingPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Trending {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function SubscribersPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Subscribers {...props} urlParams={urlParams} />
            </React.Fragment>  
}
function CreateAccountPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <CreateAccount {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function YourAccountPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <YourAccount {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function ZopinionsPage(props)
{
    let urlParams = useParams();
    return <Zopinions {...props} urlParams={urlParams} />
}
function RecoverPassowrdPage(props)
{
    let urlParams = useParams();
    return <RecoverPasswordComponent {...props} urlParams={urlParams} />
}
export default function RouterSwitch(props) {
    function changeRoute(route) {
        document.location.href = route;
    }

    return (<Routes>
                <Route path="/recover/password/:uET/:uCF" element={ <RecoverPassowrdPage {...props} changeRoute={changeRoute} />} />
                <Route path="/" element={<Home {...props} changeRoute={changeRoute} />} />
                <Route path="/search/:query" element={<HomePage {...props} changeRoute={changeRoute} />} />
                <Route path="/filters" element={<FiltersList {...props} changeRoute={changeRoute} />} />
                <Route path="/link/:linksHash" element={<NewsItemPage {...props} changeRoute={changeRoute} />} />
                <Route path="/bookmarks" element={<BookmarksPage {...props} changeRoute={changeRoute} />} />
                <Route path="/trending" element={<TrendingPage {...props} changeRoute={changeRoute} />} />
                <Route path="/trending/today" element={<TrendingWhitelistedPage {...props} changeRoute={changeRoute} />} />
                <Route path="/subscribers/:subscribersID" element={<SubscribersPage {...props} changeRoute={changeRoute} />} />
                <Route path="/create-account" element={<CreateAccountPage {...props} changeRoute={changeRoute} />} />
                <Route path="/your-account" element={<YourAccountPage {...props} changeRoute={changeRoute} />} />
                <Route path="/zopinions" element={<ZopinionsPage {...props} changeRoute={changeRoute} />} />
            </Routes>)
}