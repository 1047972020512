export default function Style(theme) {
  const style = {
    root: {
      marginTop: 30
    },
    logimFormPaper: {
      margin: '0 auto',
      maxWidth: 750,
    },
    loginInput: {
      margin: "8px 0"
    },
    button: {
      margin: "8px 0"
    }
  }
    return style
}