import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/HomeStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Login from '../Login/Login'
import NewsFeed from '../NewsFeed/NewsFeed'
import SearchFeed from '../SearchFeed/SearchFeed';

export default class UserOrderComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        if(this.props.urlParams && this.props.urlParams.query) {
            this.getLinksAction(this.props.urlParams.query)
        } else if (!this.getLocalStorage('user')){
            this.getLinksAction("")
        }
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.getSubscribersFeedAction()
            },
            getLinksAction: (q) => {
                that.getLinksAction(q)
            },
            postSubscriberKeyword: (subscribersKeyword) => {
                that.postSubscriberKeyword(subscribersKeyword)
            }
        })
    }
    postSubscriberKeyword(subscribersKeyword) {
        const that = this;
        let postObj = {
            subscribersKeyword: subscribersKeyword,
            subscribersDeliveryPeriod: 0,
            subscribersActive: 1
        }
        if(that.getLocalStorage('user')) {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '?limit=4&accountToken=' + that.getLocalStorage('user').tokensHash,postObj,'POST',null, (data) => {
                let subscribers = that.props.getManagerState().subscribers;
                subscribers = subscribers.concat(data.items[0])
                that.props.setManagerState({subscribers: subscribers})
            })
        }    
    }
    getSubscribersFeedAction() {
        const that = this;
        let subscribersIDRequest = ''
        if(that.props.getManagerState().subscribersID) {
            const subscribersID = [parseInt(that.props.getManagerState().subscribersID)]
            subscribersIDRequest += '&subscribersID[0]=' + subscribersID;
        }
        that.props.setManagerState({loadingNewsFeed: true, subscribersFeedItems: []},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS_FEED_ALL + '?limit=4&accountToken=' + that.getLocalStorage('user').tokensHash + subscribersIDRequest,{},'GET','subscribersFeed', (data) => {
                    that.props.setManagerState({oldestLinksID:0 ,subscribersFeedItems: data.items, loadingNewsFeed: false})

                })
            }    
        })
    }
    getLinksAction(q) {
        const that = this;
        that.props.setManagerState({loadingSearchFeed: true, searchFeedItems: []},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_LINKS + '?limit=24&q=' + q,{},'GET',null, (data) => {
                that.props.setManagerState({searchFeedItems: data.items, loadingSearchFeed: false})
            }, () => {
                that.props.setManagerState({loadingSearchFeed: false})
            })
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        { this.getLocalStorage("user") ?
                        null
                        :
                        <Login {...this.buildComponentProps('Appbar')} />
                        }
                        <SearchFeed {...this.buildComponentProps('Appbar')} />
                        {
                            this.getLocalStorage('user')
                        ?
                        <NewsFeed {...this.buildComponentProps('Appbar')} />
                        :
                        null
                        }
                    </div>
                </div>
    }
}
