import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Style from '../Styles/NewsFeedStyle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Settings from '../../../Settings/Settings';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Comments from './Comments';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Paper from '@mui/material/Paper';

export default function NewsItem(props) {
  const classes = Style();

  const [isBookmarked, setIsBookmarked] = React.useState(false)
  const [showComments, setShowComments] = React.useState(false)
  const [showFullDescription, setShowFullDescription] = React.useState(false);
  const [commentText, setCommentText] = React.useState("")
  const [comments, setComments] = React.useState([])
  const [emptyCommentsList, setEmptyCommentsList] = React.useState(false)
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods,
      toggleShowComments: () => {
        setShowComments(!showComments)
      },
      getComments: () => {
        getComments()
      }
  }


  const getComments = () => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_COMMENTS_1 + props.link.linksID + Settings.API_ENDPOINT_COMMENTS_2, {}, 'GET',null, (data) => {
        setComments(data.items)
        if (data.items.length === 0) setEmptyCommentsList(true)
    })
  }

  const componentProps = {
      ...props.componentProps
  }

  const parseUrl = (url) => {
      var a = document.createElement('a');
      a.href = url;
      return a.hostname;
  }

  const postBookmarksAction = () => {
      if(componentMethods.getLocalStorage('user')) {
          componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_POST_BOOKMARKS + '?accountToken=' + componentMethods.getLocalStorage('user').tokensHash ,{bookmarksLinksLinksID:props.link.linksID },'POST',null, (data) => {
            setIsBookmarked(true)
          })
      }    
  }
  const postComment = () => {
    if(componentMethods.getLocalStorage('user')) {
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_COMMENTS_1 + props.link.linksID + Settings.API_ENDPOINT_COMMENTS_2 + '?accountToken=' + componentMethods.getLocalStorage('user').tokensHash ,{commentsLinksID:props.link.linksID, commentsText: commentText },'POST',null, (data) => {
          getComments()
          setCommentText("")
        })
    }    
}
  return (
    <Card sx={{ maxWidth: 345 }} style={classes.newsFeedItem}>
      <CardHeader
        title={<a target="_blank" style={classes.link} href={props.link.linksUrl}>{props.link.linksTitle}</a>}
        subheader={<span style={classes.sublink}>{parseUrl(props.link.linksUrl)} - {props.link.linksCrawledDateTime}</span>}
      />
      {
        props.link.linksImageUrl ?
      <CardMedia
        component="img"
        height="194"
        image={props.link.linksImageUrl}
        alt={props.link.linksTitle}
      />
      :
      null
      }
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {props.link.linksDescription.length > 67 && !showFullDescription ?
              <React.Fragment>
                {props.link.linksDescription.substring(0, 66)}
                <IconButton onClick={(e) => {setShowFullDescription(!showFullDescription)}} >
                  <MoreHorizIcon />
                </IconButton>
              </React.Fragment>
              :
              <React.Fragment>
                {props.link.linksDescription.length > 67 ?
                  <React.Fragment>
                    <React.Fragment>
                      {props.link.linksDescription}
                    </React.Fragment>
                      <IconButton aria-label="delete" onClick={(e) => {setShowFullDescription(!showFullDescription)}} >
                      <MoreHorizIcon />
                    </IconButton>
                  </React.Fragment>
                  :
                  props.link.linksDescription
                }
              </React.Fragment>
            }
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="share" onClick={() => {
            navigator.clipboard.writeText(Settings.SITE_URL + 'link/' + props.link.linksHash)
            componentMethods.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
            }}>
          <ContentCopyIcon />
        </IconButton>
          {isBookmarked || !props.showBookmark ?
            null
            :
            <React.Fragment>
              {componentMethods.getLocalStorage('user') ?
                  <IconButton aria-label="share" onClick={() => {
                    postBookmarksAction()
                }}>
                <StarBorderIcon />
              </IconButton>
              :
              null
              }
          </React.Fragment>
          }
            <Comments setShowComments={setShowComments} componentConstants={componentConstants} componentMethods={componentMethods} linksID={props.link.linksID} />
      </CardActions>
      <CardContent>
      {
        showComments && componentMethods.getLocalStorage('user')?
        <div>
        <TextField
            id="filled-multiline-flexible"
            label={componentConstants.language.LANG_COMMENT}
            multiline
            maxRows={4}
            variant="filled"
            value={commentText}
            onChange={(e) => {setCommentText(e.target.value)}}
            fullWidth
        />
        <Button fullWidth style={classes.sendCommentButton} onClick={postComment} endIcon={<SendIcon />} variant="contained" color="primary">{componentConstants.language.LANG_SEND}</Button>
        </div>
        :
        null
        }
        {
          showComments ?
          <React.Fragment>
            {emptyCommentsList ?
              <Typography variant="body2" gutterBottom>{componentConstants.language.LANG_EMPTY_COMMENTS_LIST}</Typography>
            :
            null
            }
            {
            comments.map((item, index) => {
                return <Paper variant="outlined" style={classes.commentPager} key={index}>
                        <Typography variant="subtitle2" gutterBottom>{item.usersFirstName}:</Typography>
                        <Typography variant="subtitle1" gutterBottom>{item.commentsText}</Typography>
                      </Paper>
              })
            }
          </React.Fragment>
          :
          null
        }
      </CardContent>
    </Card>
  );
}