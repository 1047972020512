import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';
import Style from '../../NewsFeed/Styles/NewsFeedStyle'
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Settings from '../../../Settings/Settings';

export default function SwipeableTemporaryDrawer(props) {
  const classes = Style();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const logout = () => {
    componentMethods.saveLocalStorage(null, "user")
    componentMethods.changeRoute('/')
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      {componentMethods.getLocalStorage('user') ?
        <ListItem disablePadding>
            <a href="/bookmarks" color="primary" style={classes.menuDrawerLinks}>
              <ListItemButton color="primary">
                <ListItemIcon>
                  <StarIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={componentConstants.language.LANG_BOOKMARKS} />
              </ListItemButton>
            </a>
        </ListItem>
        :
        null
        }
        {componentMethods.getLocalStorage('user') ?
          <React.Fragment>
              <Divider />
              <ListItem disablePadding>
                  <a href="/your-account" color="primary" style={classes.menuDrawerLinks}>
                    <ListItemButton color="primary">
                      <ListItemIcon>
                        <AccountCircleIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary={componentConstants.language.LANG_YOUR_ACCOUNT} />
                    </ListItemButton>
                  </a>
              </ListItem>
          </React.Fragment>
        :
        null
        }
        <React.Fragment>
          <Divider />
          <ListItem disablePadding>
              <a href="/trending/today" color="primary" style={classes.menuDrawerLinks}>
                <ListItemButton color="primary">
                  <ListItemIcon>
                    <AccountCircleIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={componentConstants.language.LANG_TRENDING_TODAY} />
                </ListItemButton>
              </a>
          </ListItem>
        </React.Fragment>
        {componentMethods.hasAccess(Settings.SETTING_ACCESS_LEVEL_GOD) ?
          <React.Fragment>
            <Divider />
            <ListItem disablePadding>
                <a href="/trending" color="primary" style={classes.menuDrawerLinks}>
                  <ListItemButton color="primary">
                    <ListItemIcon>
                      <AccountCircleIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={componentConstants.language.LANG_TRENDING} />
                  </ListItemButton>
                </a>
            </ListItem>
          </React.Fragment>
          :
          null
          }
        {componentMethods.getLocalStorage('user') ?
          <React.Fragment>
            <Divider />
            <ListItem disablePadding>
                <a href="#" onClick={logout} color="primary" style={classes.menuDrawerLinks}>
                  <ListItemButton color="primary">
                    <ListItemIcon>
                      <LogoutIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={componentConstants.language.LANG_LOGOUT} />
                  </ListItemButton>
                </a>
            </ListItem>
        </React.Fragment>
        :
        null
        }
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon color="secondary" />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
          {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}