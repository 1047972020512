let languages = {
    ro: {
        LANG_USERNAME: "Nume utilizator",
        LANG_AUTHENTICATE: 'Autentificare',
        LANG_CONVERSATION: '@utilizator or #canal',
        LANG_MESSAGE: 'Mesaj',
        LANG_ENCRYPT: 'Cripteaza',
        LANG_SET_ENCRYPTION: 'Foloseste criptarea',
        LANG_ENCRYPTION_BYTE: 'Octet pentru criptare',
        LANG_ENCRYPTION_SUM: 'Suma pentru criptare',
        LANG_RESET_ENCRYPTION: 'Reseteaza criptarea',
        LANG_ENCRYPTED: 'Criptat',
        LANG_DELETE: 'Șterge',
        LANG_CANCEL: "Anulează",
        LANG_CLOSE_CHANNEL_TITLE: "Doresti sa inchizi aceasta conversatie?",
        LANG_CLOSE_CHANNEL_TEXT: "Apasand butonul STERGE vei incheia aceasta conversatie",
        LANG_DRAFT: "Mesaj: ",
        LANG_MESSAGE_ENCRYPTED: "Mesaj criptat",
        LANG_SHOPPING_CART: "Coș de cumparaturi",
        LANG_CREATE_ACCOUNT: "Creează cont",
        LANG_UPLOAD_IMAGE: "Încarcă imagine",
        LANG_UPLOAD_IMAGE_LOCATION_NAME: "Locația imaginii",
        LANG_UPLOAD:"Încarcă",
        LANG_CHOOSE_IMAGE:"Alege imagine",
        LANG_SEARCH: "Caută",
        LANG_EMAIL: "Email",
        LANG_PASSWORD: "Parolă",
        LANG_OLD_PASSWORD: "Parola veche",
        LANG_NEW_PASSWORD:"Parola nouă",
        LANG_NEW_PASSWORD_CONFIRM:"Confirmă parola nouă",
        LANG_UPDATE_ACCOUNT:"Actualizează cont",
        LANG_CONFIRM_PASSWORD: "Confirmă parola",
        LANG_DATE_OF_BIRTH_DAY: "Zi",
        LANG_DATE_OF_BIRTH_MONTH: "Lună",
        LANG_DATE_OF_BIRTH_YEAR: "An",
        LANG_DATE_OF_BIRTH: "Zi de naștere",
        LANG_UPDATED_ACCOUNT: "Contul a fost actualizat cu succes",
        LANG_SEX:"Sex",
        LANG_FEMALE:"Femeie",
        LANG_MALE:"Bărbat",
        LANG_OTHER:"Altul",
        LANG_FIRST_NAME:"Prenume",
        LANG_LAST_NAME:"Nume",
        LANG_CLOSE: "Închide",
        LANG_ERROR_INVALID_PASSWORD_CONFIRM: "Parolele nu coincid",
        LANG_ERROR_INVALID_PASSWORD: "Parolă invalidă",
        LANG_VALIDATION_REQUIRED_FIELD: "Câmp obligatoriu",
        LANG_VALIDATION_DATE_FIELD: "Câmp invalid",
        LANG_VALIDATION_INVALID_REQUEST: "Eroare în formular",
        LANG_VALIDATION_INVALID_FIELD: "Câmp invalid",
        LANG_VALIDATION_INVALID_CONFIRM_PASSWORD: "Parolă invalidă",
        LANG_SUCCESSFUL_REGISTER: "Contul a fost creat cu succes, te rugăm să te autentifici",
        LANG_BACK:"Înapoi",
        LANG_VALIDATION_UNIQUE: "Câmpul trebuie sa fie unic",
        LANG_usersEmail: "Email",
        LANG_usersDateOfBirth: "Data nașterii",
        LANG_usersFirstName: "Prenume",
        LANG_usersLastName: "Nume",
        LANG_usersPassword: "Parolă",
        LANG_usersPasswordCheck: "Parolă veche",
        LANG_usersUsersSexID: "Sex",
        LANG_ERROR_NON_EXISTING_USERS: "Utilizatorul nu este înregistrat",
        LANG_VALIDATION_INVALID_EMAIL: "Adresă de email invalidă",
        LANG_ERROR_NON_VALID_USER: "Utilizatorul nu există",
        LANG_VALIDATION_MIN_FIELD_LENGTH:"Câmpul nu contine destule caractere",
        LANG_YOUR_ACCOUNT: "Contul tău",
        LANG_LOGOUT: "Ieșire din cont",
        LANG_LOADING: "Se încarcă",
        LANG_ERROR_COULD_NOT_LOAD_IMAGE: "Imaginea nu a putut fi încărcată",
        LANG_UPLOAD_IMAGE_DESCRIPTION: "Descrierea imaginii",
        LANG_CHANGE_PASSWORD: "Schimbă parola",
        LANG_CANCEL_CHANGE_PASSWORD: "Anulează schimbarea parolei",
        LANG_IMAGE_PUBLISHED: "Imagine publicată",
        LANG_YOUR_PICTURES: "Imaginile tale",
        LANG_SEARCH_YOUR_IMAGES: "Caută în imaginile tale",
        LANG_SEARCH_USER_IMAGES: "Caută în imaginile utilizatorului",
        LOAD_IMAGES:"Încarcă imaginile",
        LANG_SAVE:"Salvează",
        LANG_IMAGE_UPDATED_SUCCESSFULLY: "Imaginea a fost actualizată cu succes",
        LANG_IMAGE_COULD_NOT_UPDATE: "Imaginea nu a putut fi actualizată, încercați din nou",
        LANG_DELETE_IMAGE_MESSAGE: "Ești sigur că dorești să ștergi imaginea?",
        LANG_DELETE_IMAGE_MESSAGE_TITLE: "Șterge imaginea",
        LANG_IMAGE_DELETED: "Imaginea a fost ștearsă",
        LANG_IMAGE_NOT_DELETED: "Imaginea nu putut fi ștearsă",
        LANG_CLOSE_IMAGE: "Închide imaginea",
        LANG_HOME: "Acasă",
        LANG_SEARCH_ALL_IMAGES:"Caută imagini",
        LANG_QUANTITY: "Cantitate",
        LANG_DIMENSIONS:"Dimensiune",
        LANG_TOTAL: "Total",
        LANG_SUBTOTAL: "Subtotal",
        LANG_ADD_TO_SHOPPING_CART: "Adaugă în coș",
        LANG_CURRENCY: "lei",
        LANG_DELETE_SHOPPINGCARTITEM_MESSAGE: "Ești sigur că dorești sa ștergi elementul din coșul de cumpărături?",
        LANG_DELETE_SHOPPINGCARTITEM_MESSAGE_TITLE: "Șterge elementul din coș",
        LANG_ADDRESS_STREET: "Strada",
        LANG_SAVE_ADDRESS: "Salvează adresa",
        LANG_COUNTY: "Județ",
        LANG_ADDRESS_NUMBER: "Nr.",
        LANG_ADDRESS_BLOCK: "Bloc",
        LANG_ADDRESS_COUNTRY: "Țara",
        LANG_ADDRESS_ENTRANCE: "Scara",
        LANG_ADDRESS_APARTMENT: "Apt.",
        LANG_ADDRESS_CITY: "Localitate",
        LANG_ADDRESS_PHONE_NUMBER: "Număr telefon",
        LANG_ADDRESS_FLOOR: "Etaj",
        LANG_ADDRESS_OBSERVATIONS: "Observații",
        LANG_ADDRESS_FIRST_NAME: "Prenume",
        LANG_ADDRESS_LAST_NAME: "Nume",
        LANG_ERROR_EMPTY_ADDRESS_STREET: "Numele străzii este invalid.",
        LANG_ERROR_EMPTY_ADDRESS_LAST_NAME: "Numele din adresă este invalid.",
        LANG_ERROR_EMPTY_ADDRESS_FIRST_NAME: "Prenumele din adresă este invalid.",
        LANG_ADDRESES: "Adrese",
        LANG_ERROR_EMPTY_ADDRESS_CITY:  "Orașul din adresă este invalid.",
        LANG_ERROR_EMPTY_PHONE_NUMBER: "Numărul de telefon din adresă este invalid.",
        LANG_ERROR_EMPTY_COUNTIES: "Județul din adresă este invalid",
        LANG_ERROR_EMPTY_BLOCK_AND_NUMBER: "Adresa nu conține număr și/sau bloc",
        LANG_SUCCESSFULY_SAVED_ADDRESS: "Adresa a fost salvată cu succes",
        LANG_ADD_ADDRESS: "Adaugă adresă",
        LANG_CANCEL_ADD_ADDRESS: "Anulează adăugarea adresei",
        LANG_EDIT_ADDRESS: "Modifică adresa",
        LANG_CANCEL_EDIT_ADDRESS: "Anulează modificarea",
        LANG_DELETE_ADDRESS_MESSAGE: "Ești sigur că dorești sa ștergi adresa?",
        LANG_DELETE_ADDRESS_MESSAGE_TITLE: "Șterge adresa",
        LANG_DELETE_COMPATIBLE_PRODUCT_MESSAGE: "Ești sigur că dorești sa ștergi acest produs compatibil?",
        LANG_DELETE_COMPATIBLE_PRODUCT_MESSAGE_TITLE: "Șterge produsul compatibil",
        LANG_ADDRESS_NOT_DELETED: "Adresa nu a putut fi ștearsă",
        LANG_ADDRESS_DELETED: "Adresa a fost ștearsă",
        LANG_PROCEED_TO_CHECKOUT: "Trimite comanda",
        LANG_SEND_ORDER: "Trimite comanda",
        LANG_DELIVERY_ADDRESS: "Adresă de livrare",
        LANG_BILLING_ADDRESS: "Adresă de facturare",
        PAYMENT_METHOD_RAMBURS_ON_DELIVERY: "ramburs",
        LANG_PAYMENT_METHOD_RAMBURS: "Ramburs la curier",
        LANG_PAYMENT_METHODS: "Metode de plată",
        LANG_PAPER_TYPE_CANVAS: "canvas",
        LANG_PAPER_TYPE_PHOTO_PAPER: "hârtie fotografică - A3",
        LANG_PRODUCT_CODE: "Cod produs",
        DELIVERY_METHOD_COURIER: "courier",
        LANG_DELIVERY_METHOD: "Metodă de livrare",
        LANG_DELIVERY_METHOD_COURIER: "Curier rapid",
        LANG_ORDER_OBSERVATIONS: "Observații comandă",
        LANG_ERROR_EMPTY_SHOPPINGCART_ITEMS: "Coșul de cumpărături este gol",
        LANG_ERROR_EMPTY_DELIVERY_ADDRESS: "Adresa de livrare nu a fost selectată",
        LANG_ERROR_EMPTY_BILLING_ADDRESS: "Adresa de facturare nu a fost selectată",
        LANG_ERROR_EMPTY_PAYMENT_METHOD: "Metoda de plată nu a fost selectată",
        LANG_ERROR_EMPTY_DELIVERY_METHOD: "Metioda de livrare nu a fost selectată",
        LANG_SUCCESSFULY_SENT_ORDER: "Comanda a fost trimisă cu succes",
        LANG_FAILED_SEND_ORDER: "Comanda nu a putut fi trimisă",
        LANG_SELECT_DIMENSIONS: "Alege dimensiune",
        LANG_SELECT_QUANTITY: "Alege cantitate",
        LANG_ORDERS: "Comenzi",
        LANG_ORDERS_ID: "Id comandă",
        LANG_ORDERS_USERS_FIRST_NAME_USERS_LAST_NAME: "Nume / Prenume",
        LANG_ORDERS_DELIVERY_ADDRESS: "Adresă de livrare",
        LANG_ORDERS_BILLING_ADDRESS: "Adresă de facturare",
        LANG_ORDERS_STATUS: "Status comandă",
        LANG_ORDERS_TOTAL: "Total",
        LANG_ORDERS_DELIVERY_METHOD: "Metodă de livrare",
        LANG_ORDERS_PAYMENT_METHOD: "Metodă de plată",
        LANG_ORDERS_OBSERVATIONS: "Observații",
        LANG_ORDER_UPDATED: "Comanda a fost actualizată",
        LANG_ORDER_NOT_UPDATED: "Comanda nu a fost actualizată",
        LANG_ORDERS_STATUSES_PLACED: "Comandă plasată",
        LANG_ORDERS_STATUSES_PROCESSING: "Comandă în procesare",
        LANG_ORDERS_STATUSES_CANCELED: "Comandă anulată",
        LANG_ORDERS_STATUSES_IN_DELIVERY: "Comandă în livrare",
        LANG_ORDERS_STATUSES_DELIVERED: "Comandă livrată",
        LANG_ORDERS_STATUSES_PAYMENT_PENDING: "Comandă cu plata in așteptare",
        LANG_UPDATE_ORDERS_STATUS: "Actualizează status",
        LANG_UPDATE_PAYMENTS_STATUS: "Actualizează status",
        LANG_ORDERS_ITEMS: "Elemente comandă",
        LANG_UNIT_OF_MEASURE: "mm",
        LANG_ORDERS_SHOW_ITEMS: "Afișează elemente",
        LANG_ORDERS_HIDE_ITEMS: "Ascunde elemente",
        LANG_ORDER_ITEMS_DIALOG_TITLE: "Comanda #",
        LANG_ORDER_ITEMS_VARIANTS_AND_IMAGE: "Cod produs / imagine",
        LANG_UPDATE_ORDERS_ITEMS_QUANTITY: "Actualizează cantitate",
        LANG_PAPER_TYPE: "Tip hârtie",
        LANG_ACTIONS:"Acțiuni",
        LANG_CONFIRM_DELETE: "Confirmă ștergerea",
        LANG_SEARCH_ORDERS: "Caută comenzi",
        LANG_ERROR_EMPTY_ORDERS_EMAIL: "Adresa de email nu este completată",
        LANG_ERROR_EMPTY_FIRM:"Nu ați selectat nicio persoană juridică",
        LANG_ORDER_EMAIL: "Adresa de email",
        LANG_MONETIZATION: "Monetizare",
        LANG_MONETIZATION_UNIT: "Monetizare (unitar)",
        LANG_MONETIZATION_TOTAL: "Monetizare (total)",
        LANG_MONETIZATION_TOTAL_THIS_MONTH: "Total luna aceasta: ",
        LANG_PAYMENTS_DATE: "Perioadă",
        LANG_PAYMENTS_STATUS: "Status plată",
        LANG_PAYMENTS_AMOUNT: "Total",
        LANG_PAYMENTS_STATUS_NOT_SENT: "Plată netrimisă",
        LANG_PAYMENTS_STATUS_SENT: "Plată trimisă",
        LANG_PAYMENTS: "Plăți",
        LANG_SHOW_CURRENT_REWARDS: "Afișează monetizarea curentă",
        LANG_HIDE_CURRENT_REWARDS: "Ascunde monetizarea curentă",
        LANG_RECOVER_PASSWORD: "Ai uitat parola?",
        LANG_RECOVER: "Recuperează",
        LANG_SUCCESSFUL_RECOVER_EMAIL_SENT: "Emailul de recuperare a parolei a fost trimis.",
        LANG_CONFIRM_NEW_PASSWORD: "Confirmă noua parolă",
        LANG_PASSWORD_CHANGED_SUCCESSFULLY: "Parola a fost schimbată cu succes",
        LANG_PASSWORD_NOT_CHANGED: "Parola nu a putut fi schimbată",
        LANG_PASSWORD_MISMATCH: "Parolele nu coincid",
        LANG_PASSWORD_EMPTY: "Parola este goală",
        LANG_IMAGE_PRE_TITLE: "Tablou de",
        LANG_PAYMENT_UPDATED: "Plata a fost actualizată",
        LANG_ORDER_DETAILS: "Detailii comandă",
        LANG_YOUR_ORDERS: "Comenzile tale",
        LANG_ORDER: "Comandă",
        LANG_ORDER_ITEMS_VARIANT_ID: "ID Produs",
        LANG_ORDER_ITEMS_IMAGE: "Imagine",
        LANG_UNIT_PRICE: "Preț unitar",
        LANG_ITEM_ADDED_TO_CART:"Elementul a fost adăugat în coșul de cumpărături",
        LANG_ITEM_NOT_ADDED_TO_CART: "Elementul nu a putut fi adăugat în coșul de cumpărături",
        LANG_EMPTY_USER_ORDER: "Ne pare rău, dar această comandă fie nu există, fie trebuie să te autentifici pentru a o vizualiza ori nu ai acces la ea.",
        LANG_COOKIE_SNACKBAR_TEXT: "Folosim modulul cookies pentru a îmbunătăți experiența pe acest site.",
        LANG_ACCEPT_COOKIE_POLICY: "Acceptă cookies",
        LANG_OUR_MISSION: "Misiunea noastră",
        LANG_CONTACT: "Contact",
        LANG_COOKIE_POLICY: "Politica cookie",
        LANG_TERMS_AND_CONDITIONS: "Termeni și condiții",
        LANG_ORDERS_EMAIL: "Email comandă",
        LANG_ERROR_ON_LOGIN: "Eroare la autentificare",
        LANG_DETAILS: "Detalii",
        LANG_DELIVERY_METHOD_SAME_DAY: "Same Day Curier",
        LANG_DELIVERY_METHOD_FAN_CURIER: "Fan Curier",
        LANG_TOTAL_ORDER: "Total (incl. methodă de livrare)",
        LANGUAGE_LINK_COPPIED_TO_CLIPBOARD: "Adresa URL a fost copiată",
        LANG_ORDER_COMPLETED_TITLE: "Comandă plasată cu succes",
        LANG_ORDER_COMPLETED_TEXT: "Vom trimite un email către adresa ",
        LANG_ORDER_COMPLETED_TEXT_1: " pentru a te ține la curent cu starea comenzii",
        LANG_AGREE_TERMS_AND_CONDITIONS: "Sunt de acord cu",
        LANG_AGREE_TERMS_AND_CONDITIONS_NAME: "termenii și condițiile",
        LANG_ERROR_TERMS_AND_CONDITIONS: "Trebuie să agreezi termenii și condițiile",
        LANG_CONTINUE_SHOPPING: "Continuă cumpărăturile",
        LANG_ARTICLE_ID: "ID Articol",
        LANG_PRICE: "Preț",
        LANG_INSERT_QUANTITY: "Introdu cantitate",
        LANG_SEARCH_PRODUCTS: "Caută produse",
        LANG_STOCK: "Stoc",
        LANG_EQUIPMENTS: "Echipamente",
        LANG_JURIDICAL_PERSON: "Persoana juridică",
        LANG_FIRM_NAME: "Denumire firmă",
        LANG_CREATE_ACCOUNT_CIF: "CIF (Cod de identificare fiscală)",
        LANG_CREATE_ACCOUNT_NO_ORC: "Nr. O.R.C.(Numărul de ordine din registrul comerțului)",
        LANG_CREATE_ACCOUNT_FIRM_ADDRESS: "Adresă sediu social",
        LANG_CREATE_ACCOUNT_FIRM_BANK: "Banca",
        LANG_ADD_FIRM: "Adaugă persoană juridica",
        LANG_ERROR_INVALID_PASSWORD: "Parolă invalidă",
        LANG_VALIDATION_REQUIRED_FIELD: "Câmp obligatoriu",
        LANG_VALIDATION_DATE_FIELD: "Câmp invalid",
        LANG_VALIDATION_INVALID_REQUEST: "Eroare în formular",
        LANG_VALIDATION_INVALID_FIELD: "Câmp invalid",
        LANG_SUCCESSFUL_REGISTER: "Contul a fost creat cu succes, te rugăm să te autentifici",
        LANG_BACK:"Înapoi",
        LANG_VALIDATION_UNIQUE: "Câmpul trebuie sa fie unic",
        LANG_usersEmail: "Email",
        LANG_usersDateOfBirth: "Data nașterii",
        LANG_usersFirstName: "Prenume",
        LANG_usersLastName: "Nume",
        LANG_usersPassword: "Parolă",
        LANG_usersUsersSexID: "Sex",
        LANG_ERROR_NON_EXISTING_USERS: "Utilizatorul nu este înregistrat",
        LANG_VALIDATION_INVALID_EMAIL: "Adresă de email invalidă",
        LANG_ERROR_NON_VALID_USER: "Utilizatorul nu există",
        LANG_VALIDATION_MIN_FIELD_LENGTH:"Câmpul nu contine destule caractere",
        LANG_ORDER_ITEMS_ARTICLE_ID_AND_PRODUCT_ID: "ID Articol / ID Produs",
        LANG_FIRMS: "Persoane juridice",
        LANG_ADD_FIRM: "Adaugă persoană juridică",
        LANG_SAVE_FIRM: "Salvează persoană juridică",
        LANG_CANCEL_ADD_FIRM: "Anulează adăugarea persoanei juridice",
        LANG_FIRM_SAVED: "Persoana juridică a fost salvată",
        LANG_UPDATE_FIRM: "Modifică persoana juridică",
        LANG_CANCEL_EDIT_FIRM: "Anulează modificarea persoanei juridice",
        LANG_CHECK_FIRM_TEXT: "Doresc factură pe persoană juridică",
        LANG_FIRM_NOT_SAVED: "Persoana juridică nu a putut fi salvată",
        LANG_REGISTER_TO_ADD_FIRMS: "Pentru a primi factură pe persoană juridică este nevoie să vă",
        LANG_REGISTER: "înregistrați",
        LANG_ARTICLE_NAME: "Denumire articol",
        LANG_PRODUCT_CATEGORY: "Categorie produs",
        LANG_PRODUCT_EQUIPMENT_LIST: "Echipamente compatibile",
        LANG_COMPATIBLE_PRODUCTS:"Produse compatibile",
        LANG_COMPATIBLE_PRODUCT_DELETED: "Produsul compatibil a fost sters",
        LANG_COMPATIBLE_PRODUCTS_NOT_DELETED: "Produsul compatibil nu a putut fi sters",
        LANG_SUCCESSFULY_SAVED_COMPATIBLE_PRODUCT: "Produsul compatibil a fost salvat",
        LANG_EDIT_COMPATIBLE_PRODUCT: "Modifică produsul",
        LANG_SAVE_COMPATIBLE_PRODUCT: "Salvează produsul compatibil",
        LANG_CANCEL_EDIT_COMPATIBLE_PRODUCT: "Anulează modificarea produsului compatibil",
        LANG_COMPATIBLE_PRODUCT_ID: "ID Produs Compatibil",
        LANG_COMPATIBLE_PRODUCT_NAME: "Nume produs compatibil",
        LANG_COMPATIBLE_PRODUCT_COMPATIBLE_PRODUCTS: "Produse compatibile",
        LANG_COMPATIBLE_PRODUCTS_NAME: "Denumire produs compatibil",
        LANG_ADD_COMPATIBLE_PRODUCT: "Adaugă produs compatibil",
        LANG_COMPATIBLE_PRODUCTS_SEARCH_PRODUCT: "Caută un produs",
        LANG_COULD_NOT_SAVE_COMPATIBLE_PRODUCT: "Nu am putut crea conexiunea de compatibilitate",
        LANG_EMPTY_RESULTSET: "Niciun rezultat",
        LANG_DELETE_COMPATIBLE_PRODUCT_PRODUCT_MESSAGE_TITLE: "Șterge compatibilitatea pentru aceste produse?",
        LANG_DELETE_COMPATIBLE_PRODUCT_PRODUCT_MESSAGE: "Dorești să ștergi compatibilitatea acestor două produse?",
        LANG_SEARCH_COMPATIBLE_PRODUCT: "Cauta produs",
        LANG_COMPATIBLE_PRODUCTS_MANUFACTURER: "Producător",
        LANG_COMPATIBLE_PRODUCTS_TYPE: "Tip produs",
        LANG_COMPATIBLE_PRODUCTS_CODE: "Cod produs",
        LANG_IMAGES: "Imagini",
        LANG_NEW_IMAGES: "Imagini noi",
        LANG_EXISTING_IMAGES: "Imagini existente",
        LANG_UPLOAD_IMAGES: "Încarcă imaginile",
        LANG_EXISTING_IMAGES: "Imagini existente",
        LANG_CODE_OEM: "Cod OEM"
    },
    en: {
        LANG_EMAIL:"Email",
        LANG_PASSWORD: "Password",
        LANG_AUTHENTICATE: "Authenticate",
        LANG_USERNAME: 'Username',
        LANG_CONVERSATION: '@username or #channel',
        LANG_MESSAGE: 'Message',
        LANG_ENCRYPT: 'Encrypt',
        LANG_SET_ENCRYPTION: 'Set encryption',
        LANG_ENCRYPTION_BYTE: 'Encryption byte',
        LANG_ENCRYPTION_SUM: 'Encryption sum',
        LANG_RESET_ENCRYPTION: 'Reset encryption',
        LANG_ENCRYPTED: 'Encrypted',
        LANG_DELETE: 'Delete',
        LANG_CANCEL: "Cancel",
        LANG_CLOSE_CHANNEL_TITLE: "Do you want to close this conversation?",
        LANG_CLOSE_CHANNEL_TEXT: "By clicking DELETE you will close this conversation",
        LANG_DRAFT: "Draft: ",
        LANG_MESSAGE_ENCRYPTED: "Ecrypted message",
        LANGUAGE_LINK_COPPIED_TO_CLIPBOARD: "Link copied to clipboard",
        LANG_SELECT_SUBSCRIBER: "Select filter",
        LANG_LOADING_SUBSCRIBERS: "Loading filters",
        LANG_GENERATING_FEED: "Generating your feed, please be patient ...",
        LOAD_MORE: "Load more",
        LOAD_MORE_FOR: "Load more for",
        LANG_SEARCH_ON_ZOOTZLE: "Search on Zootzle ...",
        LANG_BOOKMARKS: "Bookmarks",
        LANG_SEARCH_RESULTS_FOR: "Search results for",
        LANG_NO_RESULTS: "No results",
        LANG_ADD_TO_WATCHLIST: "Add to watchlist: ",
        LANG_FILTER_SUBSCRIBERS: "Filter watchlist",
        LANG_FIRST_NAME:"First name",
        LANG_LAST_NAME:"Last name",
        LANG_CONFIRM_PASSWORD: "Confirm password",
        LANG_DATE_OF_BIRTH_DAY: "Day",
        LANG_DATE_OF_BIRTH_MONTH: "Month",
        LANG_DATE_OF_BIRTH_YEAR: "Year",
        LANG_DATE_OF_BIRTH: "Date of birth",
        LANG_SEX:"Gender",
        LANG_FEMALE:"Female",
        LANG_MALE:"Male",
        LANG_OTHER:"Other",
        LANG_TERMS_AND_CONDITIONS: "Terms and conditions",
        LANG_AGREE_TERMS_AND_CONDITIONS: "I agree to",
        LANG_AGREE_TERMS_AND_CONDITIONS_NAME: "terms and conditions",
        LANG_ERROR_TERMS_AND_CONDITIONS: "You have to agree to terms and conditions",
        LANG_CREATE_ACCOUNT: "Create account",
        LANG_ERROR_INVALID_PASSWORD_CONFIRM: "Passwords mismatch",
        LANG_ERROR_INVALID_PASSWORD: "Invalid password",
        LANG_VALIDATION_REQUIRED_FIELD: "Required field",
        LANG_VALIDATION_DATE_FIELD: "Invalid date field",
        LANG_VALIDATION_INVALID_REQUEST: "Error in form",
        LANG_VALIDATION_INVALID_FIELD: "Invalid field",
        LANG_VALIDATION_INVALID_CONFIRM_PASSWORD: "Invalid password",
        LANG_SUCCESSFUL_REGISTER: "Account created successfully, please log in",
        LANG_BACK:"Back",
        LANG_VALIDATION_UNIQUE: "Field must be unique",
        LANG_usersEmail: "Email",
        LANG_usersDateOfBirth: "Date of birth",
        LANG_usersFirstName: "First name",
        LANG_usersLastName: "Last name",
        LANG_usersPassword: "Password",
        LANG_usersPasswordCheck: "Old password",
        LANG_usersUsersSexID: "Gender",
        LANG_ERROR_NON_EXISTING_USERS: "User is not registered",
        LANG_VALIDATION_INVALID_EMAIL: "Invalid email address",
        LANG_ERROR_NON_VALID_USER: "User does not exist",
        LANG_VALIDATION_MIN_FIELD_LENGTH:"Field does not contain enough characters",
        LANG_LOGOUT: "Log out",
        LANG_UPDATE_ACCOUNT: "Update account",
        LANG_YOUR_ACCOUNT: "Your account",
        LANG_CHANGE_PASSWORD: "Change password",
        LANG_CANCEL_CHANGE_PASSWORD: "Cancel change password",
        LANG_OLD_PASSWORD: "Old password",
        LANG_NEW_PASSWORD:"New password",
        LANG_NEW_PASSWORD_CONFIRM:"Confirm new password",
        LANG_UPDATED_ACCOUNT: "Account updated successfully",
        LANG_TRENDING: "Trending",
        LANG_COMMENTS: "Comments",
        LANG_COMMENT: "Comment",
        LANG_EMPTY_COMMENTS_LIST: "No comments to show",
        LANG_SEND: "Send",
        LANG_EMPTY_TRENDING_WHITELISTED_LIST: "Nothing's trending yet",
        LANG_TRENDING_TODAY: "Trending today",
        LANG_RECOVER_PASSWORD: "Forgotten password?",
        LANG_RECOVER: "Recover",
        LANG_SUCCESSFUL_RECOVER_EMAIL_SENT: "Recovery password email was sent.",
        LANG_CONFIRM_NEW_PASSWORD: "Confirm new password",
        LANG_PASSWORD_CHANGED_SUCCESSFULLY: "Password was successfully changed",
        LANG_PASSWORD_NOT_CHANGED: "Password could not be changed. Please check the latest email from us and click the recover password link from the latest email.",
        LANG_PASSWORD_MISMATCH: "Passwords should be the same",
        LANG_PASSWORD_EMPTY: "Empty password",
    }
}

export default languages
