import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Appbar from '../Appbar/Appbar';
import Login from '../Login/Login'
export default class NewsItemPage extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getLinkByHashAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    getLinkByHashAction() {
        const that = this;
        that.props.setManagerState({loadingNewsItem: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_LINK_BY_HASH + that.props.urlParams.linksHash ,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({newsItem: data.items[0], loadingNewsItem:false})
                    document.querySelector('meta[name="description"]').setAttribute("content", data.items[0].linksDescription);
                    document.title = data.items[0].linksTitle;
                }
            })
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    { this.getLocalStorage("user") ?
                    null
                    :
                    <Login {...this.buildComponentProps('Appbar')} />
                    }
                    {
                        this.props.getManagerState().loadingNewsItem ?
                                <CircularProgress style={classes.cilcularProgress} text={this.props.getManagerState().language.LANG_GENERATING_FEED} />
                            :
                            <Grid container spacing={2}>
                            { 
                                <Grid item xs={12} md={12} lg={12}>
                                    <NewsItem link={this.props.getManagerState().newsItem} {...this.buildComponentProps("Appbar")} />
                                </Grid>
                            }
                            </Grid>
                    }                                        
                </div>
    }
}
