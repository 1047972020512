import * as React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Style from '../Styles/LoginStyle'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Settings from '../../../Settings/Settings'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoginIcon from '@mui/icons-material/Login';
import RecoverPasswordScreen from './RecoverPasswordScreen'
export default function LoginForm(props) {
  const [logInUsersEmail, setLogInUsersEmail] = React.useState()
  const [logInUsersPassword, setLogInUsersPassword] = React.useState()
  const classes = Style();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }

  const logInAction = () => {
      componentMethods.setManagerState({loadingLogin: true})
      let requestLogInObject = {
          usersEmail: logInUsersEmail,
          usersPassword:logInUsersPassword,
      }
      componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_LOGIN,requestLogInObject,'POST',null, (data) => {
        componentMethods.saveLocalStorage(data.items[0], 'user')
        componentMethods.setManagerState({loadingLogin: false, searchFeedItems: []})
      }, {}, false, (data) => {componentMethods.setLogInErrors(data)}, () => {
          componentMethods.setManagerState({loadingLogin: false})
      })
  }
  const createAccountAction = () => {
    componentMethods.changeRoute('/create-account');
  }

  if(componentMethods.getManagerState().recoverPasswordScreen) {
    return <Paper style={classes.logimFormPaper}>
              <RecoverPasswordScreen componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            </Paper>
  }

  return (
      <Paper style={classes.logimFormPaper}>
    		<TextField
              required
              id="loginEmail"
              fullWidth
              label={componentConstants.language.LANG_EMAIL}
              style={classes.loginInput}
              onChange={(e)=> {setLogInUsersEmail(e.target.value)}}
            />     
        <TextField
              required
              id="loginPassword"
              type="password"
              style={classes.loginInput}
              fullWidth
              label={componentConstants.language.LANG_PASSWORD}
              onChange={(e)=> {setLogInUsersPassword(e.target.value)}}
            />  
            {
              componentMethods.getManagerState().loadingLogin ?
              <CircularProgress />
              :
              <React.Fragment>
                <Button startIcon={<LoginIcon />} fullWidth onClick={logInAction} color="secondary" style={classes.button} variant="contained">{componentConstants.language.LANG_AUTHENTICATE}</Button>
                <Button startIcon={<PersonAddIcon />} fullWidth onClick={createAccountAction} color="secondary" style={classes.button} variant="outlined">{componentConstants.language.LANG_CREATE_ACCOUNT}</Button>
                <Button disabled={componentMethods.getManagerState().loadingLogin} onClick={componentMethods.showRecoverPasswordScreen} variant="contained" color="primary" fullWidth={true} className={classes.closeButton}>
                  {componentConstants.language.LANG_RECOVER_PASSWORD}
              </Button>
              </React.Fragment>

            }  
      </Paper>
  );
}

